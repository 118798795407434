import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../states/hooks';
import { storeId } from '../states/forgotPasswordSlice';
import isEmail from 'validator/lib/isEmail';
import BotBar from '../components/BotBar';
import LazyloadImg from 'components/LazyLoadingImg';
import { BACKEND_URL } from 'config';
import {
  TextField,
  Button,
  IconButton,
  FormHelperText,
  FormControl,
  Collapse,
  Alert,
  useTheme,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CLIENT_BACKGROUND, CLIENT_LOGO } from 'constants/constants';

const ForgotPassword = () => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    const response = await fetch(`${BACKEND_URL}/api/v1/anonymous/request`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        operation: 'forgot-password-dashboard-admin',
        context: {
          email: email,
        },
      }),
    });

    try {
      const json = await response.json();
      setLoading(false);
      dispatch(storeId(json.data.id));
      navigate('/enter-otp');
    } catch (e) {
      setLoading(false);
      navigate('/enter-otp');
    }
  };

  return (
    <div
      className='login-container bg-local flex flex-col justify-center items-center h-full bg-cover bg-center'
      style={{ backgroundImage: `url(${CLIENT_BACKGROUND})` }}
    >
      <div className='flex flex-col justify-center h-full w-1/4 min-w-[400px]'>
        <Collapse in={error.length > 0}>
          <Alert
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setError('');
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            sx={{ mb: 2, borderRadius: 3, fontSize: 12 }}
          >
            {error}
          </Alert>
        </Collapse>
        <div className='bg-white flex flex-col justify-between items-center py-4 w-full h-2/3 gap-3 rounded-2xl relative '>
          <LazyloadImg
            src={CLIENT_LOGO}
            alt={'Logo'}
            style={{ height: '80px', marginTop: '20px', marginBottom: '20px' }}
          ></LazyloadImg>
          <div className='flex flex-col justify-between h-3/4 w-4/5 font-medium gap-2'>
            <Link to='/login'>
              <div className='flex flex-row justify-start items-center text-black gap-2 font-bold'>
                <ArrowBackIcon></ArrowBackIcon>
                <div className='text-black text-base'>Back to Log In</div>
              </div>
            </Link>
            <div className='text-black flex flex-col justify-center items-start gap-1'>
              <div className='text-left text-lg font-bold'>Forgot Password</div>
              <div className='text-left font-normal text-sm'>
                Please enter your email to receive the OTP to reset your
                password
              </div>
            </div>
            <FormControl>
              <TextField
                fullWidth
                id='outlined-basic'
                label='Email'
                variant='outlined'
                InputLabelProps={{ style: { fontSize: 16, paddingRight: 6 } }}
                inputProps={{ style: { fontSize: 16 } }}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                error={isEmail(email) || email.length === 0 ? false : true}
              />
              {!isEmail(email) && email !== '' ? (
                <div className='h-4 flex flex-col justify-start items-start'>
                  <FormHelperText id='component-helper-text' error>
                    Invalid Email Format
                  </FormHelperText>
                </div>
              ) : (
                <div className='h-4'></div>
              )}
            </FormControl>
            <Button
              disabled={!isEmail(email) || loading}
              sx={{
                backgroundColor: palette.primary.main,
              }}
              className='py-2 rounded-lg text-base font-semibold tracking-wider'
              variant='contained'
              onClick={handleSubmit}
            >
              {loading ? (
                <div className='flex flex-row justify-center items-center gap-4'>
                  <CircularProgress
                    className='h-5 w-5'
                    color='inherit'
                  ></CircularProgress>
                  <div>{'Sending OTP...'}</div>
                </div>
              ) : (
                <div>{'Send OTP'}</div>
              )}
            </Button>
          </div>
        </div>
      </div>
      <BotBar></BotBar>
    </div>
  );
};

export default ForgotPassword;
