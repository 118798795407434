import Cookies from 'js-cookie';
import { LocalStorageKeyEnum } from '../types/localStorage';
import { useAppDispatch } from 'states/hooks';
import { fetchGetAuthenticated } from './auth.service';
import { enqueueSnackbar } from 'notistack';
import { UserManualType } from 'types/legal';

export class DocumentService {
	static getDashboardManual = async (token: string) => {
		const res = await fetchGetAuthenticated({
			route: '/v1/dashboard/user-manual',
			token,
		});
		if (!res?.data || !res?.data?.pdfUrl) {
			console.log('Error showing dashboard manual.');
			enqueueSnackbar('Something went wrong. Please contact admin.', {
				variant: 'info',
			});
			return;
		}

		const data = res.data as UserManualType;
		const dashboardFileUrl = data?.pdfUrl;

		window.open(dashboardFileUrl);
	};
}
