import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_INACTIVITY_MILISECONDS } from 'constants/constants';
import { LocalStorageService } from 'services/localStorage.service';
import { LocalStorageKeyEnum } from 'types/localStorage';

interface userSlice {
	email: string;
	userAuth: string;
	name: string;
	hasNotViewDashboardManualNotification: boolean;
	userId: string;
	roles: string[];
}

const initialState: userSlice = {
	email: '',
	userAuth: '',
	name: '',
	hasNotViewDashboardManualNotification: false,
	userId: '',
	roles: [],
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		login: (state, action: PayloadAction<userSlice>) => {
			state.email = action.payload.email;
			state.name = action.payload.name;
			state.userAuth = action.payload.userAuth;
			state.hasNotViewDashboardManualNotification =
				action.payload.hasNotViewDashboardManualNotification;
			state.userId = action.payload.userId;
			state.roles = action.payload.roles;
		},
		logout: (state) => {
			LocalStorageService.clearAll();
			state.email = '';
			state.name = '';
			state.userAuth = '';
			state.hasNotViewDashboardManualNotification = false;
			state.userId = '';
			state.roles = [];
		},
		closeDashboardManualNotification: (state) => {
			state.hasNotViewDashboardManualNotification = false;
			const stored = LocalStorageService.getWithExpiry(
				LocalStorageKeyEnum.USER,
				false,
			);
			if (stored) {
				LocalStorageService.setWithExpiry(
					LocalStorageKeyEnum.USER,
					{
						...stored,
						hasNotViewDashboardManualNotification: false,
					},
					DEFAULT_INACTIVITY_MILISECONDS,
				);
			}
		},
	},
});

// Action creators are generated for each case reducer function
export const { login, logout, closeDashboardManualNotification } =
	userSlice.actions;
export default userSlice.reducer;
