import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface forgotPasswordSlice {
  id: string,
  otp: string,
}

const initialState: forgotPasswordSlice = {
  id: "",
  otp: "",
}
export const forgotPasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    storeId: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    storeOtp: (state, action: PayloadAction<string>) => {
      state.otp = action.payload
    },
    remove: state => {
      state.id = "";
      state.otp = ""
    }
  }
})

// Action creators are generated for each case reducer function
export const {storeId, storeOtp, remove } = forgotPasswordSlice.actions
//export const selectUuid = (state: RootState) => state.changePassword.uuid;
export default forgotPasswordSlice.reducer