import { useState, useEffect, useContext } from 'react';
import { BACKEND_URL } from '../config';
import { useAppDispatch, useAppSelector } from '../states/hooks';
import {
	Box,
	Button,
	CircularProgress,
	Stack,
	Typography,
	useTheme,
} from '@mui/material';
import { CloudOutlined, Download, SaveAlt } from '@mui/icons-material';
import { logout } from '../states/userSlice';
// import { useInterval } from '../helpers/useInterval';
import { FLOATING_BUTTON_COLOR } from 'constants/colors';
import { UnifiedDashboardCtx } from 'packages/unified-dashboard/unified-dashboard.provider';
import { INTEGRATED_WITH_UP } from 'constants/constants';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { LocalStorageService } from 'services/localStorage.service';

type ExportType = 'CROSS_TAB' | 'PDF' | 'DATA';

// Default selector from embedded tableau
const SELECTORS = 'tableau-viz';
// https://embedding.tableauusercontent.com/preview/enums/crosstabfileformat.html
const FILE_FORMAT = 'xlsx';
// Change this if data team changing some of this name on direct tableau
const WORKSHEET_NAME = 'Prospect Details';
// const STORY_NAME = 'KYC Dashboard';

const EMPTY_DEALERSHIP_ERROR = '[E_EmptyDealershipList]';

function Tab({ roleData }: { roleData: { roleId: number; roleType: string } }) {
	const { palette } = useTheme();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const userAuth = useAppSelector((state) => state.user.userAuth);
	const ctx = useContext(UnifiedDashboardCtx);
	const [html, setHTML] = useState<string | null>(null);
	// const [disclaimerText, setDisclaimerText] = useState<string | null>(null);
	const [showExport] = useState<boolean>(false);

	// const isKYCDashboardTabSelected = () => {
	//   // Needed to do this because we can't put document value on hooks dependency
	//   const tempSelector: any = document.querySelector(SELECTORS);
	//   const tempStoryPointCaption =
	//     tempSelector?.workbook?._workbookImpl?._activeSheetImpl
	//       ?._activeStoryPointImpl?._storyPointInfoImpl?.caption;
	//   if (tempStoryPointCaption === STORY_NAME && !showExport) {
	//     setShowExport(true);
	//     return;
	//   }
	//   if (tempStoryPointCaption !== STORY_NAME && showExport) {
	//     setShowExport(false);
	//     return;
	//   }
	// };

	// TODO: adjust Obsolete because of the new simplified tableau
	// every second check if KYC Dashboard tab selected, if yes then show export button
	// useInterval(isKYCDashboardTabSelected, 1000);

	const logoutUP = () => {
		dispatch(logout());
		// clearAuthData();
		ctx.dispatch({ type: 'UNIFIED_DASHBOARD_ACTION_LOGOUT' });
		LocalStorageService.clearAll();
		LocalStorageService.clearAuthV2();
		enqueueSnackbar('Account logged out', {
			variant: 'success',
		});
		navigate('/login');
	};

	useEffect(() => {
		// TODO: move this API call to the page level. For now this is used when roleId is not provided yet but it already render then we don't want to do any api call
		if (roleData.roleType === '' || roleData.roleId === undefined) return;
		const apiCall = async () => {
			try {
				const response = await fetch(
					`${BACKEND_URL}/api/v1/tableau/html/by-role`,
					{
						method: 'POST',
						body: JSON.stringify({
							filters: {},
							...roleData,
						}),
						headers: {
							Authorization: `Bearer ${userAuth || ctx.state.mftAccessToken}`,
						},
					},
				);

				if (!response.ok) {
					const data = await response.json();
					if (data.error === '[E_UnsupportedRole]') {
						logoutUP();
						return;
					} else if (data.error === EMPTY_DEALERSHIP_ERROR) {
						setHTML(EMPTY_DEALERSHIP_ERROR);
						return;
					}
				}
				let text = await response.text();
				if (response.ok) {
					setHTML(text);
				}
			} catch (e) {
				console.log(e);
				logoutUP();
			}
		};
		apiCall();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roleData.roleId, roleData.roleType]);

	const openExportDialogAsync = async (type: ExportType) => {
		const tableauViz: any = document.querySelector(SELECTORS);
		if (tableauViz) {
			switch (type) {
				case 'CROSS_TAB': {
					tableauViz.displayDialogAsync('export-cross-tab');
					break;
				}
				case 'PDF': {
					tableauViz.displayDialogAsync('export-pdf');
					break;
				}
				case 'DATA': {
					tableauViz.displayDialogAsync('export-data');
					break;
				}
				default: {
					console.log('type not inputted');
				}
			}
			// setDisclaimerText(
			//   'Setelah klik tombol "Download", harap tunggu beberapa saat.\nLama download tergantung pada kecepatan internet dan performa mesin anda.',
			// );
		}
		// else {
		//   // setDisclaimerText('Harap tunggu tableau selesai ditampilkan.');
		// }
	};

	const exportCrossTab = () => {
		const tableauViz: any = document.querySelector(SELECTORS);
		tableauViz.exportCrosstabAsync(WORKSHEET_NAME, FILE_FORMAT);
	};

	const generateExportButton = (type: 'DIALOG' | 'PROSPECT_DETAILS') => {
		// Notes: type DIALOG = show list of tables to be selected on active worksheet
		// Notes: type PROSPECT_DETAIL = only export a certain prospect details table (if somehow someone change that table name, it need to be adjusted here)
		switch (type) {
			case 'DIALOG': {
				return (
					<div
						style={{
							position: 'fixed',
							zIndex: 99,
							left: INTEGRATED_WITH_UP ? '200px' : '16px',
							bottom: INTEGRATED_WITH_UP ? '16px' : '96px',
							display: 'flex',
							justifyContent: 'left',
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<Button
							id="export-cross-tab"
							variant="outlined"
							onClick={() => openExportDialogAsync('CROSS_TAB')}
							style={{
								width: '152px',
								height: '46px',
								backgroundColor: 'white',
								borderRadius: '100px',
								borderColor: palette.primary.main,
							}}
						>
							<Box
								display={'flex'}
								justifyContent={'space-between'}
								alignItems={'center'}
								gap={'8px'}
							>
								<SaveAlt
									height={'20px'}
									width={'20px'}
									htmlColor={palette.primary.main}
								/>
								<p
									style={{
										color: palette.primary.main,
										fontSize: '16px',
										fontWeight: 600,
										textTransform: 'capitalize',
									}}
								>
									Download
								</p>
							</Box>
						</Button>

						{/* {disclaimerText ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <p style={{ fontSize: '12px' }}>{disclaimerText}</p>
                <Close
                  transform={'scale(0.6)'}
                  style={{ cursor: 'pointer', color: 'red' }}
                  onClick={() => setDisclaimerText(null)}
                />
              </div>
            ) : null} */}
					</div>
				);
			}
			case 'PROSPECT_DETAILS': {
				if (!showExport) {
					return null;
				}
				return (
					<div
						style={{
							position: 'fixed',
							zIndex: 99,
							left: '15px',
							bottom: '15px',
							display: 'flex',
							justifyContent: 'left',
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<Button
							id="export-cross-tab"
							onClick={() => exportCrossTab()}
							style={{
								backgroundColor: FLOATING_BUTTON_COLOR,
								height: '46px',
								borderRadius: '25px',
								padding: '22px 17px',
							}}
						>
							<Download
								height={'20px'}
								width={'20px'}
								htmlColor="white"
								style={{ marginRight: '4px' }}
							/>
							<p
								style={{
									color: 'white',
									fontSize: '14px',
									fontWeight: 600,
									textTransform: 'capitalize',
								}}
							>
								Prospect Details
							</p>
						</Button>
					</div>
				);
			}
		}
	};

	return html === EMPTY_DEALERSHIP_ERROR ? (
		<Stack
			alignItems={'center'}
			gap={1}
			style={{
				background: 'white',
				borderWidth: 1,
				borderColor: 'grey-50',
				borderRadius: '16px',
				padding: '24px',
			}}
		>
			<CloudOutlined sx={{ fontSize: 66, color: '#C0C9D5' }} />
			<Typography textAlign={'center'} color={'#74849C'}>
				<Typography fontSize={16} fontWeight={700}>
					No Assigned Branch
				</Typography>
				<Typography fontSize={14} fontWeight={500}>
					Sorry, no active branch is assigned to you, please contact your
					administrator to get a branch assigned to you.
				</Typography>
			</Typography>
		</Stack>
	) : (
		<div className="tab h-full overflow-visible">
			{generateExportButton('DIALOG')}
			{html ? (
				<div dangerouslySetInnerHTML={{ __html: html }} />
			) : (
				<CircularProgress />
			)}
		</div>
	);
}

export default Tab;
