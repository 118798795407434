import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './states/store';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { UnifiedDashboardPluginComponent } from 'packages/unified-dashboard/unified-dashboard.provider';

import 'tvs-unified-lib/dist/assets/index.css';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);
root.render(
	<Provider store={store}>
		<SnackbarProvider
			autoHideDuration={3000}
			maxSnack={2}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<UnifiedDashboardPluginComponent>
				<App />
			</UnifiedDashboardPluginComponent>
		</SnackbarProvider>
	</Provider>,
);
