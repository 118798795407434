/* eslint-disable react/require-default-props */
import { CSSProperties } from 'react';

interface LazyloadImgProps {
  src: string;
  alt: string;
  style?: CSSProperties;
}

const LazyloadImg = ({ src, alt, style }: LazyloadImgProps) => (
  <img src={src} alt={alt} style={style} />
);

export default LazyloadImg;
