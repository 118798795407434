/**
 * This is the state management logic for the header of the unified dashboard shared component.
 */

import Cookies from 'js-cookie';
import { LocalStorageService } from 'services/localStorage.service';
import {
	LocalStorageKeyEnum,
	LocalStorageType_User,
} from '../../types/localStorage';

export enum DASHBOARD_LOGIN_STATUS {
	LOGGED_OUT = 'LOGGED_OUT',
	LOGGED_IN = 'LOGGED_IN',
	SESSION_TIMED_OUT = 'SESSION_TIMED_OUT',
	SESSION_INVALID = 'SESSION_INVALID',
	PENDING = 'PENDING',
	UNKNOWN = 'UNKNOWN',
	ERROR = 'ERROR',
}

export type StateType = {
	displayName: string;
	loginStatus: DASHBOARD_LOGIN_STATUS;
	userData: any | null;
	mftAccessToken: any | null;
	upRefreshToken: any | null;
};

export type UnifiedDashboardState_Action =
	| { type: 'UNIFIED_DASHBOARD_ACTION_LOGOUT' }
	| { type: 'UNIFIED_DASHBOARD_SET_USERDATA' }
	| { type: 'UNIFIED_DASHBOARD_SET_ACCESS_TOKEN'; payload: string }
	| { type: 'UNIFIED_DASHBOARD_LOAD_SESSION'; payload: LocalStorageType_User };

export const DEFAULT: StateType = {
	displayName: 'Placeholder Name',
	loginStatus: DASHBOARD_LOGIN_STATUS.LOGGED_OUT,
	userData: null,
	mftAccessToken: null,
	upRefreshToken: null,
};

export function reducer(
	state: StateType,
	action: UnifiedDashboardState_Action,
): StateType {
	switch (action.type) {
		case 'UNIFIED_DASHBOARD_ACTION_LOGOUT': {
			return {
				...state,
				displayName: '',
				loginStatus: DASHBOARD_LOGIN_STATUS.LOGGED_OUT,
				mftAccessToken: null,
				upRefreshToken: null,
				userData: null,
			};
		}
		case 'UNIFIED_DASHBOARD_SET_ACCESS_TOKEN': {
			return {
				...state,
				mftAccessToken: action.payload,
			};
		}
		case 'UNIFIED_DASHBOARD_LOAD_SESSION': {
			// TODO: @Debashish -- verify a session object to determine login status
			return {
				...state,
				mftAccessToken: action.payload.userAuth,
				upRefreshToken: action.payload.unifiedPortalAuth,
				displayName: action.payload.name,
				loginStatus: action.payload.loginStatus as DASHBOARD_LOGIN_STATUS,
				userData: action.payload,
			};
		}
		default:
			return state;
	}
}
