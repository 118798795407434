import { BACKEND_URL } from 'config';
import jwtDecode from 'jwt-decode';
import { AppDispatch } from 'states/store';
import { logout } from 'states/userSlice';
import { LocalStorageType_User } from '../types/localStorage';

const handleFetchError = (e: any, dispatch?: AppDispatch) => {
	if (e.error === '[E_NoRoleAccess]') {
		dispatch?.(logout());
	}
};

export const fetchGetAuthenticated = async ({
	route,
	token,
	params,
	dispatch,
}: {
	route: string;
	token: string;
	params?: any;
	dispatch?: AppDispatch;
}) => {
	try {
		const res = await fetch(`${BACKEND_URL}/api${route}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		const json = await res.json();
		if (!res.ok) {
			handleFetchError(json, dispatch);
		}
		return json;
	} catch (e) {
		handleFetchError(e, dispatch);
		return {
			success: false,
			data: null,
		};
	}
};

export const fetchPostAuthenticated = async ({
	route,
	body,
	token,
	params,
	dispatch,
}: {
	route: string;
	body?: Record<string, any>;
	token?: string;
	params?: any;
	dispatch?: AppDispatch;
}) => {
	try {
		const res = await (
			await fetch(`${BACKEND_URL}/api${route}`, {
				method: 'POST',
				headers: token
					? {
							Authorization: `Bearer ${token}`,
					  }
					: undefined,
				body: body ? JSON.stringify({ ...body }) : undefined,
			})
		).json();
		return res;
	} catch (e) {
		handleFetchError(e, dispatch);
		return {
			success: false,
			data: null,
		};
	}
};

export function populateLegacyLoginContext(
	v2LocalStorageData: LocalStorageType_User,
) {
	// Legacy code needs to removed, in favor of v2 context
	const { email, name, userAuth, hasNotViewDashboardManualNotification } =
		v2LocalStorageData;

	const decodedToken = jwtDecode(v2LocalStorageData.userAuth) as {
		[key: string]: any;
	};
	const userId: string = decodedToken.sub;
	const roles: string[] = decodedToken.context.roles;

	const storageObject = {
		email,
		name,
		userAuth,
		hasNotViewDashboardManualNotification,
		userId,
		roles,
	};
	return storageObject;
}
