import { PaletteOptions, createTheme } from '@mui/material/styles';
import { DEPLOYMENT_ENV } from 'config';
import { ClientEnum, INTEGRATED_WITH_UP } from 'constants/constants';

const generalPalette: PaletteOptions = {
	common: { black: '#000000', white: '#FFFFFF' },
	text: {
		primary: '#000000',
		disabled: '#7F7F7F',
	},
	success: {
		light: '#ccffce',
		main: '#12bf51',
		dark: '#006241',
		contrastText: '#013c28',
		100: '#E8FDE7',
		200: '#CCFFCE',
		300: '#7BF08B',
		400: '#20E254',
		500: '#12BF51',
		600: '#008357',
		700: '#006241',
		800: '#013C28',
	},
	info: {
		light: '#FDF8E7',
		main: '#FFCD2E',
		dark: '#DD6006',
		contrastText: '#CA3000',
		100: '#FFFDF8',
		200: '#FDF8E7',
		300: '#FFF1C5',
		400: '#FFE388',
		500: '#FFCD2E',
		600: '#E99820',
		700: '#DD6006',
		800: '#CA3000',
	},
	warning: {
		light: '#FFEBE5',
		main: '#FF630B',
		dark: '#B11901',
		contrastText: '#7D1100',
		100: '#FFF9F8',
		200: '#FFEBE5',
		300: '#FFD9CC',
		400: '#FFAF8D',
		500: '#FF630B',
		600: '#D83F08',
		700: '#B11901',
		800: '#7D1100',
	},
	error: {
		light: '#feb2b2',
		main: '#e53e3e',
		dark: '#9b2c2c',
		contrastText: '#fff',
		100: '#FED7D7',
		200: '#FEB2B2',
		300: '#FC8181',
		400: '#F56565',
		500: '#E53E3E',
		600: '#C53030',
		700: '#9B2C2C',
		800: '#822727',
	},
	grey: {
		100: '#EDF2F7',
		200: '#E2E8F0',
		300: '#CBD5E0',
		400: '#A0AEC0',
		500: '#718096',
		600: '#4A5568',
		700: '#2D3748',
		800: '#1A202C',
	},
};

const aristaPalette: PaletteOptions = {
	primary: {
		light: '#757ce8',
		main: '#2b388f',
		dark: '#4250b0',
		contrastText: '#fff',
	},
	secondary: {
		light: '#757ce8',
		main: '#2b388f',
		dark: '#4250b0',
		contrastText: '#fff',
	},
};

const mazdaPalette: PaletteOptions = {
	primary: {
		light: '#bfbfbf',
		main: '#171717',
		dark: '#414141',
		contrastText: '#fff',
	},
	secondary: {
		light: '#bfbfbf',
		main: '#171717',
		dark: '#414141',
		contrastText: '#fff',
	},
};

const motoracePalette: PaletteOptions = {
	primary: {
		light: '#FFAB91',
		main: '#F1592A',
		dark: '#BC2D01',
		contrastText: '#fff',
	},
	secondary: {
		light: '#FFAB91',
		main: '#F1592A',
		dark: '#BC2D01',
		contrastText: '#fff',
	},
};
const unifiedPalette: PaletteOptions = {
	primary: {
		light: '#9C8DDA',
		main: '#6956BA',
		dark: '#3F2A9B',
		contrastText: '#fff',
		50: '#F2F3FF',
		100: '#DDE0FF',
		200: '#C4C8FF',
		300: '#B1B6FF',
		400: '#9C8DDA',
		500: '#6956BA',
		600: '#3F2A9B',
		700: '#2A167F',
		800: '#150264',
	},
	secondary: {
		light: '#9C8DDA',
		main: '#6956BA',
		dark: '#3F2A9B',
		contrastText: '#fff',
		50: '#F2F3FF',
		100: '#DDE0FF',
		200: '#C4C8FF',
		300: '#B1B6FF',
		400: '#9C8DDA',
		500: '#6956BA',
		600: '#3F2A9B',
		700: '#2A167F',
		800: '#150264',
	},
};

const defaultPalette: PaletteOptions = {
	primary: {
		light: '#757ce8',
		main: '#010078',
		dark: '#002884',
		contrastText: '#fff',
		50: '#E4E4F5',
		100: '#9F9FC3',
		200: '#7978D3',
		300: '#1C1ACC',
		400: '#060498',
		500: '#010078',
		600: '#010059',
		700: '#01003d',
		800: '#000026',
	},
	secondary: {
		light: '#757ce8',
		main: '#2b388f',
		dark: '#4250b0',
		contrastText: '#fff',
		50: '#E4E4F5',
		100: '#9F9FC3',
		200: '#7978D3',
		300: '#1C1ACC',
		400: '#060498',
		500: '#010078',
		600: '#010059',
		700: '#01003d',
		800: '#000026',
	},
};

export const theme = createTheme({
	typography: { fontFamily: 'SF Pro Text' },
	palette: {
		...generalPalette,
		...(DEPLOYMENT_ENV === ClientEnum.ARISTA
			? aristaPalette
			: DEPLOYMENT_ENV === ClientEnum.MAZDA
			? mazdaPalette
			: INTEGRATED_WITH_UP
			? unifiedPalette
			: defaultPalette),
	},
});
