import {
  Box,
  FormControl,
  Select,
  ListSubheader,
  TextField,
  InputAdornment,
  MenuItem,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Check } from '@mui/icons-material';
import { RoleResponseType } from 'pages/Home';

type SelectorProps = {
  displayedOptions: RoleResponseType[];
  selectedOption?: RoleResponseType;
  onChange?: (newValue: string) => void;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
};

const DROPDOWN_WIDTH = 400;

export const Selector = ({
  displayedOptions,
  selectedOption,
  onChange,
  setSearchText,
}: SelectorProps) => {
  return (
    <Box width={`${DROPDOWN_WIDTH}px`} height={'33px'}>
      <FormControl fullWidth>
        <Select
          // Disables auto focus on MenuItems and allows TextField to be in focus
          MenuProps={{ autoFocus: false }}
          id={'select-id'}
          value={selectedOption?.label}
          onChange={(event) => onChange && onChange(event.target.value)}
          onClose={() => setSearchText('')}
          // This prevents rendering empty string in Select's value
          // if search text would exclude currently selected option.
          renderValue={() => selectedOption?.label}
          style={{ textAlign: 'left', height: '33px' }}
        >
          <ListSubheader style={{ padding: '4px 12px 4px 12px' }}>
            <TextField
              size='small'
              autoFocus
              fullWidth
              placeholder='Search...'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayedOptions.map(({ label, id, key }) => {
            const isSelected =
              id === selectedOption?.id && key === selectedOption?.key;
            return (
              <MenuItem
                key={`${id}-${key}`}
                value={label}
                style={{
                  background: isSelected ? 'rgba(43, 56, 143, 0.1)' : undefined,
                  padding: '12px',
                }}
              >
                <Tooltip title={label}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: `${DROPDOWN_WIDTH - 24}px`,
                    }}
                  >
                    <span
                      style={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        color: isSelected ? '#2B388F' : '#414141',
                        fontWeight: isSelected ? 600 : 400,
                      }}
                    >
                      {label}
                    </span>
                    {isSelected ? <Check style={{ color: '#2B388F' }} /> : null}
                  </div>
                </Tooltip>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
