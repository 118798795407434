import jwtDecode from 'jwt-decode';
import {
	FAILURE_WITH_ERROR,
	SUCCESS_WITH_DATA,
} from 'packages/unified-dashboard/unified-dashbaord.utils';
import { LocalStorageService } from 'services/localStorage.service';
import { LocalStorageKeyEnum } from 'types/localStorage';

export class LegacyComponentPlugin {
	static async queryLocalSession(): Promise<{
		success: boolean;
		data: {
			email: string;
			name: string;
			userAuth: string;
			hasNotViewDashboardManualNotification: boolean;
			userId: string;
			roles: string[];
		} | null;
		error?: string;
	}> {
		const localStorageObj = LocalStorageService.getWithExpiry(
			LocalStorageKeyEnum.USER,
		);
		// TODO: add zod validation
		if (!localStorageObj) {
			return FAILURE_WITH_ERROR('[E_Session_Data_Invalid]');
		}

		const { email, name, userAuth, hasNotViewDashboardManualNotification } =
			localStorageObj;

		const decodedToken = jwtDecode(userAuth) as { [key: string]: any };
		const userId = decodedToken.sub;
		const roles = decodedToken.context.roles;
		const storageObject = {
			email,
			name,
			userAuth,
			hasNotViewDashboardManualNotification,
			userId,
			roles,
		};
		return SUCCESS_WITH_DATA(storageObject);
	}
}
