import Cookies from 'js-cookie';
import { LocalStorageKeyEnum } from '../types/localStorage';

export class LocalStorageService {
	static set = (key: string, value: object, raw: boolean = false) => {
		localStorage.setItem(key, raw ? (value as any) : JSON.stringify(value));
	};

	static verifyWithZod = (obj: object, schema: any) => {
		const result = schema.safeParse(obj);
		if (!result.success) {
			console.error(result.error);
			return false;
		}
		return true;
	};

	static get = (key: string, isJSON: boolean = false) => {
		const ret = localStorage.getItem(key);
		if (!ret) return null;
		if (isJSON) {
			try {
				return JSON.parse(ret!);
			} catch (e) {
				console.log(e);
				return null;
			}
		} else {
			return ret;
		}
	};

	static setWithExpiry = (key: string, value: object, ttlMs: number) => {
		this.set(key, {
			value: {
				...value,
				// the expiration using expiry above, the lastAssigned is only needed to check whether we should log them out bcs of the inactivity on operational hour on the next day (expiry only can't determine that)
				storageDataLastAssigned: Date.now(),
			},
			expiry: Date.now() + ttlMs,
		});
	};

	static getWithExpiry = (key: string, isCheckExpiration: boolean = true) => {
		const data = this.get(key, true);
		if (!data) return null;
		if (isCheckExpiration) {
			if (Date.now() > data.expiry) {
				// FIXME: auto-removal of keys is not recommended. we still need info about who was logged in
				// Only clearAuth should delete everything
				this.remove(key);
				return null;
			}
		}
		return data.value;
	};

	static remove = (key: string) => {
		return localStorage.removeItem(key);
	};

	static clearAuth = () => {
		this.remove(LocalStorageKeyEnum.USER);
		sessionStorage.removeItem(LocalStorageKeyEnum.USER);
		Cookies.remove(LocalStorageKeyEnum.USER);
	};
	static clearAuthV2 = () => {
		this.remove(LocalStorageKeyEnum.USER_V2);
		sessionStorage.removeItem(LocalStorageKeyEnum.USER_V2);
		Cookies.remove(LocalStorageKeyEnum.USER_V2);
	};

	static clearNonAuth = () => {
		// nothing for now
	};

	static clearAll = () => {
		this.clearAuth();
		this.clearNonAuth();
	};
}
