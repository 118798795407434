import { forwardRef, useCallback } from 'react';
import { SnackbarContent, CustomContentProps, useSnackbar } from 'notistack';
import IconDialogClose from './icon-x.svg?react';

interface ReportCompleteProps extends CustomContentProps {}

const SnackbarSuccess = forwardRef<HTMLDivElement, ReportCompleteProps>((props, ref) => {
  const { id, message } = props;
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref} role="alert">
      <div className="theme-snackbar theme-snackbar-success">
        <span>{message}</span>
        <IconDialogClose className="theme-snackbar-close-button" onClick={handleDismiss} />
      </div>
    </SnackbarContent>
  );
});

export default SnackbarSuccess;
