// Deployment Environment
// right now not used at all, for future prep
export const NODE_ENV = process.env.REACT_APP_NODE_ENV || 'production';

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || '';
export const DEPLOYMENT_ENV = process.env.REACT_APP_DEPLOYMENT_ENV || '';
export const ASSETS_BASE_URL = process.env.REACT_APP_ASSETS_BASE_URL || '';

export const OVERRIDE_INACTIVITY_SECONDS =
	process.env.REACT_APP_OVERRIDE_INACTIVITY_SECONDS || '';

// Unified Portal Integration
export const UP_ENDPOINT = process.env.REACT_APP_UP_DOMAIN || '';
