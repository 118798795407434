import { useState, useEffect } from 'react';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import IconSidebarCaretDown from './icon-arrow-down.svg?react';
import IconHelp from './icon-help-outline.svg?react';

type SidebarItemTypes = {
  label: string;
  icon?: string;
  activeUrl?: string[];
  onClick?: () => void;
  children?: SidebarItemTypes[];
};

type SidebarTypes = {
  title: string;
  items: SidebarItemTypes[];
  logoImage: string;
  /**
   * Pass react router dom v6 location object here
   */
  location: any;
  onClickLogo?: () => void;
  openSidebar?: boolean;
  hasSupportChat?: boolean;
  supportChatLoading?: boolean;
  onSupportChatClick?: () => void;
};

const Sidebar = (props: SidebarTypes) => {
  const {
    title,
    items,
    location,
    logoImage,
    openSidebar = true,
    onClickLogo = null,
    hasSupportChat = true,
    supportChatLoading = false,
    onSupportChatClick,
  } = props;
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const [openMobileSidebar, setOpenMobileSidebar] = useState<boolean>(openSidebar);

  const renderChildren = (children: SidebarItemTypes[], parentIndex: number) => {
    return (
      <ul
        className={`theme-sidebar-children ${activeIndex !== parentIndex && 'theme-sidebar-children-hidden'}`}
      >
        {children.map((child: SidebarItemTypes, index: number) => {
          const childUrls: string[] | undefined = child.activeUrl;
          const checkIsActive: boolean =
            childUrls !== undefined
              ? childUrls.some((url: string) => {
                  return currentUrl.includes(url);
                })
              : false;
          return (
            <li
              key={`sidebar-${parentIndex}-${index}`}
              className={`${checkIsActive && 'theme-sidebar-item-active'}`}
            >
              <div className="theme-sidebar-child-item" onClick={() => child.onClick && child.onClick()}>
                <span>{child.label}</span>
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  useEffect(() => {
    setCurrentUrl(window.location.href);
    setOpenMobileSidebar(false);
  }, [location]);

  useEffect(() => {
    setOpenMobileSidebar(openSidebar);
  }, [openSidebar]);

  return (
    <aside className={`theme-sidebar ${openMobileSidebar && 'theme-sidebar-open'}`}>
      <div className="theme-sidebar-wrapper">
        <a
          href="#"
          onClick={() => {
            setActiveIndex(-1);
            onClickLogo && onClickLogo();
          }}
          className="theme-sidebar-logo-wrapper"
        >
          <img src={logoImage} alt="TVS Digital" className="theme-sidebar-logo" />
        </a>
        <OverlayScrollbarsComponent defer>
          <ul className="theme-sidebar-menu">
            <li className="theme-sidebar-title">{title}</li>
            {items.map((item: SidebarItemTypes, index: number) => {
              const checkIsActive: boolean =
                item.activeUrl !== undefined
                  ? item.activeUrl.some((url: string) => {
                      return currentUrl.includes(url);
                    })
                  : false;
              return (
                <li key={`sidebar-${index}`}>
                  <button
                    className={`theme-sidebar-link" ${checkIsActive ? 'theme-sidebar-item-active' : ''}`}
                    onClick={() => {
                      setActiveIndex(activeIndex === index ? -1 : index);
                      item.onClick && item.onClick();
                    }}
                  >
                    {item.icon !== undefined && (
                      <img src={item.icon} className="theme-sidebar-icon" alt={item.label} />
                    )}
                    {item.label}
                    {item.children !== undefined &&
                      Array.isArray(item.children) &&
                      item.children.length > 0 && (
                        <span className="theme-sidebar-arrow">
                          <IconSidebarCaretDown
                            className={`theme-sidebar-arrow ${index === activeIndex && 'is-opened'}`}
                          />
                        </span>
                      )}
                  </button>
                  {item.children !== undefined &&
                    Array.isArray(item.children) &&
                    item.children.length > 0 &&
                    renderChildren(item.children, index)}
                </li>
              );
            })}
          </ul>
        </OverlayScrollbarsComponent>

        <div className="theme-sidebar-footer">
          <div className="logo-wrapper">
            <img src="/images/sidebar/sidebar-logo-bottom.svg" />
          </div>
          {hasSupportChat && (
            <div className="support-chat" onClick={() => onSupportChatClick && !supportChatLoading && onSupportChatClick()}>
              <IconHelp /><span>{supportChatLoading ? 'Loading Chat...' : 'Chat Support'}</span>
            </div>
          )}
          <div className="content-wrapper">
            <a href="https://www.tvsd.ai/en/privacy-policy" className="color-theme-grey-200" target="_blank">
              Privacy Policy
            </a>
            <a href="https://www.tvsd.ai/en/terms-of-use" className="color-theme-grey-200" target="_blank">
              Terms Of Use
            </a>
            <a href="https://www.tvsd.ai/en/cookie-policy" className="color-theme-grey-200" target="_blank">
              Cookie Policy
            </a>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
