import LazyloadImg from './LazyLoadingImg';
import { APEC_LOGO, POWERED_BY_OUR_LOGO } from 'constants/constants';

const BotBar = () => {
	return (
		<div className="flex flex-row justify-between items-start w-full sticky bottom-0 bg-white py-4 px-6">
			<LazyloadImg
				src={POWERED_BY_OUR_LOGO}
				alt={'logo'}
				style={{ height: '38px' }}
			></LazyloadImg>
			<LazyloadImg
				src={APEC_LOGO}
				alt={'logo'}
				style={{ height: '38px' }}
			></LazyloadImg>
		</div>
	);
};

export default BotBar;
