import jwtDecode from 'jwt-decode';
import { BACKEND_URL, OVERRIDE_INACTIVITY_SECONDS } from '../../config';
import { LocalStorageService } from '../../services/localStorage.service';
import {
	LocalStorageKeyEnum,
	LocalStorageType_User,
	LocalStorageZodValidation_User,
} from '../../types/localStorage';
import { DASHBOARD_LOGIN_STATUS } from './unified-dashboard.state';
import { DEFAULT_INACTIVITY_MILISECONDS } from '../../constants/constants';
import {
	FAILURE_WITH_ERROR,
	SUCCESS_WITH_DATA,
} from './unified-dashbaord.utils';

export class UnifiedComponentPlugin {
	/**
	 * Check LocalStorage for any saved sessions
	 *
	 * NOTE: Compatible for UD, as well as non-UD workflows
	 *
	 * Possible Errors:
	 *
	 * [E_Logged_Out]
	 * [E_Session_Data_Invalid]
	 * [E_Sesson_Times_Out] (you still get userData)
	 *
	 * @returns true if a valid session was found
	 */
	static async queryLocalSession(): Promise<{
		success: boolean;
		data: LocalStorageType_User | null;
		error?: string;
	}> {
		const localStorageObj = LocalStorageService.get(
			LocalStorageKeyEnum.USER_V2,
			true,
		) as { value: LocalStorageType_User; expiry: number };
		const lsObjValue = localStorageObj?.value;
		if (!lsObjValue) return FAILURE_WITH_ERROR('[E_Logged_Out]');
		const result = LocalStorageZodValidation_User.safeParse(lsObjValue);
		if (!result.success) {
			return FAILURE_WITH_ERROR('[E_Session_Data_Invalid]');
		}
		return SUCCESS_WITH_DATA(lsObjValue);
	}

	/**
	 * Performs the login
	 *
	 * This step also saves the session in the local storage
	 * @param token
	 */
	static login(
		token: string,
		options: {
			loginMethod?: 'UP' | 'MFT';
			upToken: string;
			name: string;
			email: string;
			hasNotViewDashboardManualNotification: boolean;
		},
	) {
		// TODO: implement UD login logic. For now, assume token means sucessfully signed in

		const decodedToken = jwtDecode(token) as { [key: string]: any };

		// TODO: @Daniel -- Please save the session to localStorage here
		const saveObject: LocalStorageType_User = {
			name: options.name,
			email: options.email,
			userAuth: token,
			hasNotViewDashboardManualNotification: false,
			storageDataLastAssigned: Date.now(),
			loginMethod: options?.loginMethod,
			roles: decodedToken.context.roles,
			loginStatus: DASHBOARD_LOGIN_STATUS.LOGGED_IN,
			unifiedPortalAuth: options.upToken,
		};

		const inactivityMiliSeconds = OVERRIDE_INACTIVITY_SECONDS
			? Number(OVERRIDE_INACTIVITY_SECONDS) * 1000
			: null;

		LocalStorageService.setWithExpiry(
			LocalStorageKeyEnum.USER_V2,
			saveObject,
			inactivityMiliSeconds && inactivityMiliSeconds > 0
				? inactivityMiliSeconds
				: DEFAULT_INACTIVITY_MILISECONDS,
		);

		return { success: true, data: saveObject };
	}

	/**
	 *
	 * Generates autotech access token from the unified token
	 *
	 * Error codes:
	 *
	 * [E_Unknown_Error]
	 * [E_Invalid_Credentials]
	 * [E_Token_Expired]
	 *
	 * @param token
	 * @returns
	 */
	static async mftTokenFromUnifiedToken(upToken: string) {
		const response = await fetch(`${BACKEND_URL}/api/v1/dashboard/auth`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ token: upToken }),
		});

		if (!response?.ok) return { success: false, error: '[E_Unknown_Error]' };

		const resp = await response.json();

		return {
			success: response.ok,
			data: resp,
			mftToken: resp.data.token,
			upToken: upToken,
		};
	}
}
