import { useContext, useEffect } from 'react';
import { UnifiedComponentPlugin } from '../packages/unified-dashboard/unified-dashboard.adapter';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	ClientContainer,
	UnifiedDashboardCtx,
} from '../packages/unified-dashboard/unified-dashboard.provider';
import { useAppDispatch } from '../states/hooks';
import { populateLegacyLoginContext } from '../services/auth.service';
import { login } from '../states/userSlice';

const Verify = () => {
	const ctx = useContext(UnifiedDashboardCtx);
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const upToken = searchParams.get('accessToken');
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (!upToken || upToken == null) {
			UnifiedComponentPlugin.queryLocalSession()
				.then((res) => {
					if (res.success && res.data) {
						ctx.dispatch({
							type: 'UNIFIED_DASHBOARD_LOAD_SESSION',
							payload: res.data,
						});
					} else {
						navigate('/');
					}
				})
				.catch((e) => {
					navigate('/');
				});
			return;
		}

		UnifiedComponentPlugin.mftTokenFromUnifiedToken(upToken)
			.then(async ({ success, data, mftToken, upToken }) => {
				if (!success || !mftToken || !upToken) {
					// token exchange failed
					navigate('/');
					return;
				}
				// Store and use the autotech access token
				const { success: mftLoginSuccess, data: mftData } =
					UnifiedComponentPlugin.login(mftToken, {
						upToken,
						loginMethod: 'UP',
						name: data.data.name,
						email: data.data.email,
						hasNotViewDashboardManualNotification:
							data.data.hasNotViewDashboardManualNotification,
					});

				if (!mftLoginSuccess) {
					// token exchange succeeded, but login failed. Could be many reasons
					navigate('/');
				}

				ctx.dispatch({
					type: 'UNIFIED_DASHBOARD_LOAD_SESSION',
					payload: mftData,
				});
				dispatch(login(populateLegacyLoginContext(mftData)));
				navigate('/legal');
			})
			.catch((e) => {
				navigate('/');
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [upToken]);

	return <ClientContainer>{null}</ClientContainer>;
};

export default Verify;
