export function SUCCESS_WITH_DATA(data: any) {
	return {
		success: true,
		data: data,
	};
}

export function FAILURE_WITH_ERROR(error: any) {
	return {
		success: false,
		data: null,
		error: error,
	};
}
