import { z } from "zod";
import { DASHBOARD_LOGIN_STATUS } from "../packages/unified-dashboard/unified-dashboard.state";

export enum LocalStorageKeyEnum {
	USER = "user",
	USER_V2 = "user_v2",
}

export type LocalStorageType_User = {
	email: string;
	name: string;
	userAuth: string;
	hasNotViewDashboardManualNotification: boolean;
	storageDataLastAssigned: number;

	loginMethod?: "UP" | "MFT";
	roles?: string[];
	loginStatus?: DASHBOARD_LOGIN_STATUS;
	unifiedPortalAuth?: string;
};

export const LocalStorageZodValidation_User = z.object({
	email: z.string(),
	name: z.string(),
	userAuth: z.string(),
	hasNotViewDashboardManualNotification: z.boolean(),
	storageDataLastAssigned: z.number(),

	loginMethod: z.string().optional().nullable(), // Password | UP
	roles: z.array(z.string()).optional().nullable(),
	loginStatus: z.string().optional().nullable(),
	unifiedPortalAuth: z.string().optional().nullable(),
});
