import { ReactNode } from 'react';
import LazyloadImg from './LazyLoadingImg';
import { CLIENT_LOGO } from 'constants/constants';
import { Box, Button, Typography } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { logout } from 'states/userSlice';
import { useNavigate } from 'react-router-dom';

const TopBar = ({
	children,
	isDisableNavigation,
}: {
	children?: ReactNode;
	isDisableNavigation?: boolean;
}) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { email, name } = useAppSelector((state) => state.user);

	return (
		<Box className="flex flex-row justify-between items-center w-full sticky bottom-0 bg-white py-3 px-10 shadow-lg">
			<Box display={'flex'} gap={'40px'} alignItems={'center'}>
				<Button
					sx={{ padding: 0 }}
					onClick={() => !isDisableNavigation && navigate('/')}
				>
					<LazyloadImg
						src={CLIENT_LOGO}
						alt={'Logo'}
						style={{ height: '28px' }}
					/>
				</Button>
				{children}
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					gap: '8px',
				}}
			>
				<Button
					sx={{
						px: '12px',
						py: '6px',
						textTransform: 'none',
					}}
					onClick={() => !isDisableNavigation && navigate('/my-account')}
				>
					<AccountBoxIcon
						color="primary"
						sx={{
							height: '16px',
							width: '16px',
						}}
					/>
					{name || email ? (
						<Typography
							color="primary"
							style={{
								fontSize: '12px',
								fontWeight: 600,
							}}
						>
							{name || email}
						</Typography>
					) : null}
				</Button>
				<Button
					sx={{
						px: '12px',
						py: '6px',
						textTransform: 'none',
					}}
					onClick={() => {
						if (!isDisableNavigation) {
							dispatch(logout());
							navigate('/login');
						}
					}}
				>
					<Typography
						style={{
							fontSize: '12px',
							fontWeight: 600,
						}}
					>
						Log Out
					</Typography>
				</Button>
			</Box>
		</Box>
	);
};

export default TopBar;
