import { ReactNode, useContext, useState } from 'react';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Navbar, Sidebar } from 'tvs-unified-lib';

import { CLIENT_LOGO, INTEGRATED_WITH_UP } from 'constants/constants';
import QuickLinks from 'components/Unified/QuickLinks';
import { UnifiedDashboardCtx } from 'packages/unified-dashboard/unified-dashboard.provider';
import { useLocation, useNavigate } from 'react-router-dom';
import { DocumentService } from 'services/document.service';
import { enqueueSnackbar } from 'notistack';
import { LocalStorageService } from 'services/localStorage.service';
import IconDownload from 'assets/icons/download.svg';
import { useTheme } from '@mui/material';
import ZendeskChatWidget from 'components/Zendesk/ChatWidget';

export interface SidebarItemProps {
	link: string;
	label: string;
	icon?: string;
	children?: SidebarItemProps[];
}
const SidebarItems = (navigate: any) => {
	return [
		{
			onClick: () => {},
			label: 'Overview',
			icon: `/images/sidebar/icon-chart-active.png`,
			activeUrl: ['/'],
		},
	];
};
const Layout = ({ children }: { children?: ReactNode }) => {
	const ctx = useContext(UnifiedDashboardCtx);
	const { palette } = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const [openMobileSidebar, setOpenMobileSidebar] = useState<boolean>(false);
	const [triggerChat, setTriggerChat] = useState<boolean>(false);

	const logout = () => {
		// clearAuthData();
		ctx.dispatch({ type: 'UNIFIED_DASHBOARD_ACTION_LOGOUT' });
		LocalStorageService.clearAll();
		LocalStorageService.clearAuthV2();
		enqueueSnackbar('Account logged out', {
			variant: 'success',
		});
		navigate('/login');
	};

	const handleViewDashboardManual = () => {
		DocumentService.getDashboardManual(ctx.state.mftAccessToken);
	};

	const userMenuLinks = [
		<a
			href="#"
			className="flex gap-2 items-center"
			onClick={handleViewDashboardManual}
		>
			<img src={IconDownload} alt="Download" />
			Management Manual
		</a>,
		<a href="#" onClick={() => logout()} className="color-theme-red-500">
			Log Out
		</a>,
	];

	const onOpenZeLiveChat = () => {
		if (typeof window !== 'undefined') {
			const windowZE = window as any;
			if (typeof windowZE?.zE?.activate === 'function') {
				windowZE.zE.activate();
			}
		}
	};

	if (INTEGRATED_WITH_UP) {
		return (
			<>
				<Navbar
					quickLinksComponent={<QuickLinks />}
					toggleSidebar={() => setOpenMobileSidebar(!openMobileSidebar)}
					userMenu={{
						fullname: ctx.state.displayName,
						links: userMenuLinks,
					}}
					hasActiveNotification={false}
				/>
				<Sidebar
					hasSupportChat
					onSupportChatClick={() => {
						onOpenZeLiveChat();
					}}
					items={SidebarItems(navigate)}
					title="Administrations"
					openSidebar={openMobileSidebar}
					location={location}
					logoImage={CLIENT_LOGO}
					onClickLogo={() => navigate('/')}
				/>
				<OverlayScrollbarsComponent defer element="main">
					<main
						className="mt-14 p-6 sm:ml-44"
						style={{ backgroundColor: palette.grey[100] }}
					>
						<div className="h-full md:h-screen overflow-scroll">{children}</div>
					</main>
				</OverlayScrollbarsComponent>
				<ZendeskChatWidget
					hideOnLoad={true}
					showWidget={location.pathname === '/' || location.pathname === ''}
					showChat={triggerChat}
					onFinishScriptLoad={() => {
						// @ts-ignore
						window.zESettings = {
							webWidget: {
								offset: {
									vertical: '80px',
									mobile: {
										vertical: '80px',
									},
								},
							},
						};
					}}
					onCloseChatWidget={() => setTriggerChat(false)}
				/>
			</>
		);
	}
	return <>{children}</>;
};

export default Layout;
