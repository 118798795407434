import { useEffect, useState } from 'react';
import Tab from '../../components/Tab';
import { useAppSelector, useAppDispatch } from '../../states/hooks';
import { fetchGetAuthenticated } from 'services/auth.service';
import { Box, Typography } from '@mui/material';
import Layout from 'layouts/Layout';
import { ClientContainer } from 'packages/unified-dashboard/unified-dashboard.provider';
import { WidthFull } from '@mui/icons-material';

export type RoleResponseType = {
	id?: number;
	key: string;
	label: string;
	dashboardTitle: string;
};

const UnifiedHome = () => {
	const dispatch = useAppDispatch();
	const { userAuth } = useAppSelector((state) => state.user);

	const [selectedRole, setSelectedRole] = useState<RoleResponseType>({
		id: undefined,
		label: '',
		key: '',
		dashboardTitle: '',
	});

	useEffect(() => {
		if (!userAuth) return;

		const fetchRoles = async () => {
			const res = await fetchGetAuthenticated({
				route: '/v1/dashboard/roles',
				token: userAuth,
				dispatch,
			});
			const mappedRoles: RoleResponseType[] = res.data.roles.map(
				(role: RoleResponseType) => ({
					id: role.id || -1,
					key: role?.key,
					label: role?.label,
					dashboardTitle: role?.dashboardTitle || '',
				}),
			);
			setSelectedRole(mappedRoles[0]);
		};
		fetchRoles();
	}, [userAuth]);

	return (
		<ClientContainer>
			<Layout>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						height: '100%',
					}}
				>
					<Box display={'flex'} marginY={2}>
						<Typography
							sx={{
								fontWeight: 600,
								fontSize: 24,
								color: '#253858',
							}}
						>
							{selectedRole.dashboardTitle}
						</Typography>
					</Box>
					<Box
						className="self-center"
						style={{ marginBottom: '80px', width: '100%' }}
					>
						<Tab
							roleData={{
								roleId: selectedRole.id ?? -1,
								roleType: selectedRole.key,
							}}
						/>
					</Box>
				</Box>
			</Layout>
		</ClientContainer>
	);
};

export default UnifiedHome;
