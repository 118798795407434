import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import IconNotification from './icon-navbar-notification.svg?react';
import IconNotificationActive from './icon-navbar-notification-active.svg?react';
import IconNotificationEmpty from './icon-navbar-notification-empty.svg?react';
import IconBell from './icon-bell.svg?react';
import IconTask from './icon-task.svg?react';
import IconHistory from './icon-history.svg?react';
import IconMenu from './icon-menu.svg?react';
import IconSidebar from './icon-sidebar.svg?react';

type NotificationItem = {
  title: string;
  description: string;
  hasRead: boolean;
  time: Date;
  sourceNames?: string | string[];
  onClick?: () => void;
};

type NavbarTypes = {
  quickLinksComponent: React.ReactNode;
  userMenu: {
    fullname: string;
    avatarImage?: string;
    links: React.ReactNode[];
  };
  hasActiveNotification: boolean;
  notifications?: NotificationItem[];
  toggleSidebar?: () => void;
  onTaskIconClick?: () => void;
  onHistoryIconClick?: () => void;
};

const getInitials = (label: string) => {
  return label
    .split(' ')
    .map((word: string) => (word[0] !== undefined ? word[0].toUpperCase() : ''))
    .join('');
};

const formatDateFromNow = (pastDate: Date) => {
  const now = moment();
  const diff = moment.duration(now.diff(pastDate));
  const seconds = diff.asSeconds();
  const minutes = diff.asMinutes();
  const hours = diff.asHours();
  const days = diff.asDays();

  if (seconds < 60) {
    return `Just Now`;
  } else if (minutes < 60) {
    return `${Math.floor(minutes)} minute${minutes === 1 ? '' : 's'} ago`;
  } else if (hours < 24) {
    return `${Math.floor(hours)} hour${hours === 1 ? '' : 's'} ago`;
  } else {
    return `${days.toFixed(0)} day${days === 1 ? '' : 's'} ago`;
  }
};

const Navbar = (props: NavbarTypes) => {
  const {
    quickLinksComponent,
    userMenu,
    hasActiveNotification,
    notifications = [],
    toggleSidebar = null,
    onTaskIconClick = null,
    onHistoryIconClick = null,
  } = props;
  const [dropdownUserVisible, setDropdownUserVisible] = useState<boolean>(false);
  const [dropdownMenuVisible, setDropdownMenuVisible] = useState<boolean>(false);
  const [dropdownNotificationVisible, setDropdownNotificationVisible] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Element)) {
      setDropdownMenuVisible(false);
      setDropdownUserVisible(false);
    }
  };

  const NotificationsNavbar = () => {
    return (
      <>
        <button
          type="button"
          style={{
            display: 'none',
            visibility: 'hidden',
          }}
          onClick={() => {
            setDropdownNotificationVisible(!dropdownNotificationVisible);
            setDropdownMenuVisible(false);
            setDropdownUserVisible(false);
          }}
        >
          {hasActiveNotification ? (
            <IconNotificationActive className="theme-navbar-icons" />
          ) : (
            <IconNotification className="theme-navbar-icons" />
          )}
        </button>

        <div
          className={`theme-navbar-dropdown theme-navbar-notif-dropdown ${
            dropdownNotificationVisible && 'dropdown-visible'
          }`}
        >
          {hasActiveNotification && notifications.length > 0 && (
            <ul>
              {notifications.map((notification: NotificationItem, index: number) => {
                return RenderNotification(notification, index);
              })}
            </ul>
          )}
          {(!hasActiveNotification || notifications.length <= 0) && (
            <div className="theme-navbar-notif-empty-wrapper">
              <IconNotificationEmpty />
              <p className="title">No notification yet</p>
              <p>Any notification you receive will show up here</p>
            </div>
          )}
        </div>
      </>
    );
  };

  const RenderNotification = (item: NotificationItem, index: number) => {
    return (
      <li key={`notification-${index}`} className={`${item.hasRead && 'theme-navbar-notif-has-read'}`}>
        {(item.sourceNames === undefined || item.sourceNames.length <= 0) && (
          <div className="theme-navbar-notif-icon icon-default">
            <IconBell />
          </div>
        )}
        {item.sourceNames &&
          Array.isArray(item.sourceNames) &&
          item.sourceNames[0] !== undefined &&
          item.sourceNames.length === 1 && (
            <div className="theme-navbar-notif-icon icon-user">
              <span className="icon-name">{getInitials(item.sourceNames[0])}</span>
            </div>
          )}
        {item.sourceNames && Array.isArray(item.sourceNames) && item.sourceNames.length > 1 && (
          <div className="theme-navbar-notif-icon-stack">
            {item.sourceNames.map((name: string, sourceNameIndex: number) => {
              return (
                <div className="theme-navbar-notif-icon icon-user" key={`notif-${index}-${sourceNameIndex}`}>
                  <span className="icon-name">{getInitials(name)}</span>
                </div>
              );
            })}
          </div>
        )}
        <div className="theme-navbar-notif-content">
          <p>{item.title}</p>
          <p>{item.description}</p>
          <small>{formatDateFromNow(item.time)}</small>
        </div>
        {!item.hasRead && <span className="theme-navbar-notif-readicon"></span>}
      </li>
    );
  };

  const QuickLinksNavbar = () => {
    return (
      <>
        <button
          type="button"
          onClick={() => {
            setDropdownMenuVisible(!dropdownMenuVisible);
            setDropdownNotificationVisible(false);
            setDropdownUserVisible(false);
          }}
        >
          <IconMenu className="theme-navbar-icons" />
        </button>

        <div
          className={`theme-navbar-dropdown theme-navbar-quicklinks-dropdown ${
            dropdownMenuVisible && 'dropdown-visible'
          }`}
        >
          {quickLinksComponent}
        </div>
      </>
    );
  };

  const UserNavbar = () => {
    return (
      <>
        <button
          type="button"
          onClick={() => {
            setDropdownUserVisible(!dropdownUserVisible);
            setDropdownNotificationVisible(false);
            setDropdownMenuVisible(false);
          }}
        >
          <span className="theme-navbar-avatar">{getInitials(userMenu.fullname)}</span>
          <span className="theme-navbar-avatar-name">{userMenu.fullname}</span>
        </button>

        <div
          className={`theme-navbar-dropdown theme-navbar-user-dropdown ${dropdownUserVisible && 'dropdown-visible'}`}
          id="user-dropdown"
        >
          <ul>
            {userMenu.links.map((link: React.ReactNode, index: number) => {
              return <li key={`user-dropdown-${index}`}>{link}</li>;
            })}
          </ul>
        </div>
      </>
    );
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <nav className="theme-navbar" ref={ref}>
      <div className="theme-navbar-wrapper">
        <div className="theme-navbar-sidebar-wrapper">
          <button
            type="button"
            className="theme-navbar-sidebar-button"
            onClick={() => {
              toggleSidebar && toggleSidebar();
              setDropdownMenuVisible(false);
              setDropdownMenuVisible(false);
            }}
          >
            <IconSidebar />
          </button>
        </div>

        <div className="theme-navbar-buttons">
          {NotificationsNavbar()}
          <button
            type="button"
            style={{
              display: 'none',
              visibility: 'hidden',
            }}
            onClick={() => {
              onTaskIconClick && onTaskIconClick();
            }}
          >
            <IconTask className="theme-navbar-icons" />
          </button>
          <button
            type="button"
            style={{
              display: 'none',
              visibility: 'hidden',
            }}
            onClick={() => {
              onHistoryIconClick && onHistoryIconClick();
            }}
          >
            <IconHistory className="theme-navbar-icons" />
          </button>
          {QuickLinksNavbar()}
          {UserNavbar()}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
