import { useNavigate } from 'react-router-dom';
import BotBar from '../components/BotBar';
import LazyloadImg from 'components/LazyLoadingImg';

import { Button, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CLIENT_BACKGROUND, CLIENT_LOGO } from 'constants/constants';

const PasswordChangeSuccess = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/');
  };

  return (
    <div
      className='login-container bg-local flex flex-col justify-center items-center h-full bg-cover bg-center'
      style={{ backgroundImage: `url(${CLIENT_BACKGROUND})` }}
    >
      <div className='flex flex-col justify-center h-full w-1/4 min-w-[400px]'>
        <div className='bg-white flex flex-col justify-between items-center py-4 w-full h-2/3 gap-3 rounded-2xl relative'>
          <LazyloadImg
            src={CLIENT_LOGO}
            alt={'Logo'}
            style={{ height: '80px', marginTop: '20px', marginBottom: '20px' }}
          ></LazyloadImg>
          <div className='flex flex-col justify-between h-3/4 w-4/5 font-medium'>
            <CheckCircleIcon className='text-blue-900 self-center h-20 w-20'></CheckCircleIcon>
            <div className='self-center flex flex-col justify-center items-center gap-1'>
              <div className='font-bold text-xl text-black'>
                New password successfully saved!
              </div>
              <div className='font-medium text-sm text-black'>
                You may now log in using your new password.
              </div>
            </div>
            <Button
              sx={{
                backgroundColor: palette.primary.main,
              }}
              className='py-2 rounded-lg text-base font-bold tracking-wider'
              variant='contained'
              disableElevation
              onClick={handleSubmit}
            >
              Go To Log In
            </Button>
          </div>
        </div>
      </div>
      <BotBar></BotBar>
    </div>
  );
};

export default PasswordChangeSuccess;
