// @todo: move this file outside component!
import CardMini from './CardMini';

import styles from './QuickLinks.module.scss';
import { UP_ENDPOINT } from 'config';
import { useContext } from 'react';
import { UnifiedDashboardCtx } from 'packages/unified-dashboard/unified-dashboard.provider';
import jwtDecode from 'jwt-decode';

interface QuickLinkServiceType {
	link: string | null;
	image: string;
	label: string;
	text: string;
	moduleName: string;
	queryParams?: string;
}

// const services: QuickLinkServiceType[] = [
// 	{
// 		link: `${UP_ENDPOINT}/redirect`,
// 		image: `${window.location.origin}/images/icon_business.png`,
// 		label: 'Business Overview',
// 		text: 'See the overall performance of your business displayed in easy-to-read data visuals.',
// 		linkText: 'Go To Overview',
// 		moduleName: '',
// 	},
// 	{
// 		link: `${UP_ENDPOINT}/redirect`,
// 		image: `${window.location.origin}/images/icon_leads.png`,
// 		label: 'Leads Management',
// 		text: 'Check the performance of your sales and leads transaction progress.',
// 		linkText: 'Go To Leads',
// 		moduleName: 'MFT',
// 	},
// 	{
// 		link: `${UP_ENDPOINT}/redirect`,
// 		image: `${window.location.origin}/images/icon_loan.png`,
// 		label: 'Loan Origination',
// 		text: 'Your go-to tools to view, apply, and review loan applications.',
// 		linkText: 'Go To LOS',
// 		moduleName: 'LOS',
// 	},
// 	{
// 		link: `${UP_ENDPOINT}/redirect`,
// 		image: `${window.location.origin}/images/icon_collections.png`,
// 		label: 'Collections',
// 		text: 'Manage collections and review the collections processes on the ground.',
// 		linkText: 'Go To Collections',
// 		moduleName: 'AIDC',
// 	},
// 	{
// 		link: `${UP_ENDPOINT}/redirect`,
// 		image: `${window.location.origin}/images/icon_admin.png`,
// 		label: 'Administrations',
// 		text: 'Manage users, apps, and product settings.',
// 		linkText: 'Go To Administrations',
// 		moduleName: 'SSP',
// 	},
// ];

const services: QuickLinkServiceType[] = [
	{
		link: null,
		image: `${window.location.origin}/images/service/business_overview.png`,
		label: 'Business Overview',
		text: 'See the overall performance of your business displayed in easy-to-read data visuals.',
		moduleName: 'business',
		queryParams: 'module=MFT',
	},
	{
		link: null,
		image: `${window.location.origin}/images/service/leads_management.png`,
		label: 'Leads Management',
		text: 'Check the performance of your sales and leads transaction progress.',
		moduleName: 'mft',
		queryParams: 'module=MFT',
	},
	{
		link: null,
		image: `${window.location.origin}/images/service/loan_origination.png`,
		label: 'Loan Origination',
		text: 'Apply and review loan applications with AI-based credit scoring tool.',
		moduleName: 'los',
		queryParams: 'module=LOS',
	},
	{
		link: null,
		image: `${window.location.origin}/images/service/collections.png`,
		label: 'Collections',
		text: 'Manage collections and review the collections processes on the ground.',
		moduleName: 'aidc',
		queryParams: 'module=AIDC',
	},
	{
		link: '/user/all',
		image: `${window.location.origin}/images/service/cashier.png`,
		label: 'Cashier',
		text: "Track and update customer's payments at the branch.",
		moduleName: 'ssp',
	},
	{
		link: '/user/all',
		image: `${window.location.origin}/images/service/campaign_management.png`,
		label: 'Campaign Management',
		text: 'Send SMS reminders, emails, and broadcast messages to team members and customers.',
		moduleName: 'ssp',
	},
	{
		link: '/user/all',
		image: `${window.location.origin}/images/service/allocations.png`,
		label: 'Allocations',
		text: 'Allocate loan accounts for collectors to collect.',
		moduleName: 'ssp',
	},
	{
		link: '/user/all',
		image: `${window.location.origin}/images/service/incentive_builder.png`,
		label: 'Incentive Builder',
		text: 'Set and manage incentives systematically to the collectors for every loans paid.',
		moduleName: 'ssp',
	},
	{
		link: '/user/all',
		image: `${window.location.origin}/images/service/telecaller.png`,
		label: 'Tele Caller',
		text: 'Contact customers and record conversations to improve your operations.',
		moduleName: 'ssp',
	},
	{
		link: '/user/all',
		image: `${window.location.origin}/images/service/after_sales_management.png`,
		label: 'After Sales Management',
		text: 'Take care of customers after the sales and follow up with any problems.',
		moduleName: 'ssp',
	},
	{
		link: '/user/all',
		image: `${window.location.origin}/images/service/dealer_management.png`,
		label: 'Dealer Management System',
		text: 'Manage your inventory.',
		moduleName: 'ssp',
	},
	{
		link: '/user/all',
		image: `${window.location.origin}/images/service/administration.png`,
		label: 'Administration',
		text: 'Manage user and application settings.',
		moduleName: 'ssp',
	},
];

const QuickLinks = () => {
	const { state } = useContext(UnifiedDashboardCtx);
	const decodedToken = state.upRefreshToken
		? (jwtDecode(state.upRefreshToken) as {
				[key: string]: any;
			})
		: null;
	// get modules from up token but filter out mft
	const tokenModules: string[] = decodedToken?.modules
		? decodedToken.modules
		: [];
	const modules = tokenModules
		.map((module: string) => module || '')
		.filter((data) => data !== 'MFT');

	const onServiceClick = (service: QuickLinkServiceType) => {
		if (service.queryParams !== undefined) {
			window.location.replace(`${UP_ENDPOINT}/redirect?${service.queryParams}`);
		}
	};

	return (
		<ul>
			{modules !== undefined &&
				services.map((service: QuickLinkServiceType, index: number) => {
					if (
						modules.includes(service.moduleName.toUpperCase()) ||
						modules.includes(service.moduleName.toLowerCase())
					) {
						return (
							<li key={`comp-quicklinks-${index}`}>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										onServiceClick(service);
									}}
								>
									<img src={service.image} alt={service.label} />
									<span>{service.label}</span>
								</a>
							</li>
						);
					} else {
						return null;
					}
				})}
		</ul>
	);
};

export default QuickLinks;
