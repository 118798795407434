import { useContext, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../states/hooks';
import { logout } from '../states/userSlice';
import TopBar from '../components/TopBar';
import { Box, Button, CircularProgress } from '@mui/material';
import {
	fetchGetAuthenticated,
	fetchPostAuthenticated,
} from 'services/auth.service';
import { useNavigate } from 'react-router-dom';
import { DEPLOYMENT_ENV } from 'config';
import {
	CLIENT_LOCALE,
	ClientEnum,
	INTEGRATED_WITH_UP,
} from 'constants/constants';
import { LegalAcceptanceType } from 'types/legal';
import {
	ClientContainer,
	UnifiedDashboardCtx,
} from 'packages/unified-dashboard/unified-dashboard.provider';
import Layout from 'layouts/Layout';
import { enqueueSnackbar } from 'notistack';
import { LocalStorageService } from 'services/localStorage.service';
import ZendeskChatWidget from 'components/Zendesk/ChatWidget';

const Legal = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const userAuth = useAppSelector((state) => state.user.userAuth);
	const ctx = useContext(UnifiedDashboardCtx);

	const [legalData, setLegalData] = useState<
		(LegalAcceptanceType & { bodyText: string }) | undefined
	>(undefined);
	const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

	useEffect(() => {
		const initiatePage = async () => {
			// console.log(userAuth);
			// console.log(ctx.state.mftAccessToken);
			if (!userAuth && !ctx.state.mftAccessToken) {
				navigate('/');
				return;
			}
			const res = await fetchGetAuthenticated({
				route: '/v1/dashboard/policy-document',
				token: ctx.state.mftAccessToken || userAuth,
				dispatch,
			});
			if (!res?.data) {
				console.log('Error showing privacy policy.');
				navigate('/');
				return;
			}

			const data = res.data as Array<LegalAcceptanceType>;
			const orderedData = data.sort(
				(
					{ policyDocument: { identifier: identifierA } },
					{ policyDocument: { identifier: identifierB } },
				) =>
					identifierA < identifierB ? -1 : identifierA > identifierB ? 1 : 0,
			);
			for (const datum of orderedData) {
				if (datum.mandatory && !datum.accepted) {
					const res = await fetch(datum.textUrl);
					if (res.status !== 200) {
						console.log('Error showing privacy policy.');
					}
					const bodyText = await res.text();
					setLegalData({ ...datum, bodyText });
					return;
				}
			}
			navigate('/');
		};
		initiatePage();
	}, []);

	const onAgreeLegal = async () => {
		if (!legalData) return;
		await fetchPostAuthenticated({
			route: '/v1/dashboard/policy-document',
			body: {
				policyId: legalData.id,
				accepted: true,
			},
			token: ctx.state.mftAccessToken || userAuth,
		});
		// enqueueSnackbar('Persetujuan Kebijakan berhasil disimpan.', {
		//   variant: 'success',
		// });
		navigate(0);
	};
	const onRejectLegal = async () => {
		dispatch(logout());

		// unified
		ctx.dispatch({ type: 'UNIFIED_DASHBOARD_ACTION_LOGOUT' });
		LocalStorageService.clearAll();
		LocalStorageService.clearAuthV2();
		enqueueSnackbar('Account logged out', {
			variant: 'success',
		});
		navigate('/login');
	};
	// const getHeaderText = (name: LegalEnum) => {
	// 	switch (name) {
	// 		case LegalEnum.EULA: {
	// 			return CLIENT_LOCALE === 'id'
	// 				? 'Perjanjian Lisensi Pengguna Akhir'
	// 				: 'End User Licence Agreement';
	// 		}
	// 		case LegalEnum.MOBILE_NOTICE: {
	// 			return CLIENT_LOCALE === 'id'
	// 				? 'Pernyataan Privasi Aplikasi Seluler'
	// 				: 'Mobile Notice';
	// 		}
	// 		case LegalEnum.PRIVACY_POLICY: {
	// 			return CLIENT_LOCALE === 'id' ? 'Kebijakan Privasi' : 'Privacy Policy';
	// 		}
	// 		case LegalEnum.TERMS_OF_USE: {
	// 			return CLIENT_LOCALE === 'id' ? 'Ketentuan Penggunaan' : 'Terms of Use';
	// 		}
	// 		case LegalEnum.COOKIE_POLICY: {
	// 			return CLIENT_LOCALE === 'id' ? 'Kebijakan Cookie' : 'Cookies Policy';
	// 		}
	// 		default: {
	// 			return '';
	// 		}
	// 	}
	// };

	if (!legalData) {
		return (
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					height: '100%',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<CircularProgress />
			</Box>
		);
	}

	return (
		<ClientContainer>
			<Layout>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'flex-start',
						height: '100%',
						overflow: 'hidden',
					}}
				>
					{INTEGRATED_WITH_UP ? null : <TopBar isDisableNavigation></TopBar>}

					<Box
						sx={{
							display: 'flex',
							height: '100%',
							justifyContent: 'center',
							alignItems: 'center',
							paddingY: '24px',
							paddingX: '100px',
							overflow: 'hidden',
						}}
					>
						<Box
							display={'flex'}
							flexDirection={'column'}
							gap={'24px'}
							height={'100%'}
							borderRadius={'12px'}
							boxShadow={'0px 0px 10px 0px #2B388F1A'}
						>
							<Box
								sx={{
									display: 'flex',
									background:
										DEPLOYMENT_ENV === ClientEnum.MAZDA ? '#E9E9E9' : '#EDF2F7',
									minHeight: '68px',
									borderRadius: '12px 12px 0px 0px',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<span style={{ fontWeight: 600, fontSize: 20 }}>
									{legalData.policyDocument.name}
								</span>
							</Box>
							<Box
								display={'flex'}
								flexGrow={1}
								paddingX={'24px'}
								sx={{ overflowY: 'scroll' }}
								onScroll={(e) => {
									const target = e.target as HTMLElement;
									const rect = target.getBoundingClientRect();
									const isAtBottom =
										Math.ceil(target.scrollTop) + Math.ceil(rect.height) >=
										target.scrollHeight;
									if (isAtBottom) {
										setIsScrolledToBottom(true);
									}
								}}
							>
								<span
									style={{
										whiteSpace: 'pre-line',
										fontWeight: 400,
										fontSize: '14px',
									}}
								>
									{legalData.bodyText}
								</span>
							</Box>
							<Box
								display={'flex'}
								justifyContent={'end'}
								gap={'12px'}
								padding={'24px'}
							>
								<Button
									variant="text"
									sx={{ width: '300px' }}
									color="error"
									onClick={onRejectLegal}
								>
									<span style={{ fontWeight: 600, fontSize: '16px' }}>
										{CLIENT_LOCALE === 'id' ? 'Tidak Setuju' : 'Decline'}
									</span>
								</Button>
								<Button
									variant="contained"
									disabled={!isScrolledToBottom}
									onClick={onAgreeLegal}
									color="primary"
									sx={{ width: '300px' }}
								>
									<span style={{ fontWeight: 600, fontSize: '16px' }}>
										{' '}
										{CLIENT_LOCALE === 'id' ? 'Setuju' : 'Agree'}
									</span>
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			</Layout>
			<ZendeskChatWidget
				onFinishScriptLoad={() => {
					// @ts-ignore
					window.zESettings = {
						webWidget: {
							offset: {
								vertical: '80px',
								mobile: {
									vertical: '80px',
								},
							},
						},
					};
				}}
			/>
		</ClientContainer>
	);
};

export default Legal;
