import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../states/hooks';
import { remove } from '../states/forgotPasswordSlice';
import isStrongPassword from 'validator/lib/isStrongPassword';
import BotBar from '../components/BotBar';
import LazyloadImg from 'components/LazyLoadingImg';
import { BACKEND_URL } from 'config';
import {
  Button,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  useTheme,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CLIENT_BACKGROUND, CLIENT_LOGO } from 'constants/constants';

const ChangePassword = () => {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const requestId = useAppSelector((state) => state.changePassword.id);
  const otp = useAppSelector((state) => state.changePassword.otp);

  const [password1, setPassword1] = useState('');
  const [showPassword1, setShowPassword1] = useState(false);
  const [password2, setPassword2] = useState('');
  const [showPassword2, setShowPassword2] = useState(false);

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    const response = await fetch(
      `${BACKEND_URL}/api/v1/anonymous/request/${requestId}/confirm`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          otp: otp,
          context: {
            newPassword: password1,
          },
        }),
      },
    );
    try {
      const json = await response.json();
      if (response.ok) {
        setLoading(false);
        dispatch(remove());
        navigate('/password-change-success');
      } else {
        setLoading(false);
        setError(json.error);
      }
    } catch (e) {
      setLoading(false);
      setError('internal server error');
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  return (
    <div
      className='login-container bg-local flex flex-col justify-center items-center h-full bg-cover bg-center'
      style={{ backgroundImage: `url(${CLIENT_BACKGROUND})` }}
    >
      <div className='flex flex-col justify-center h-full w-1/4 min-w-[400px]'>
        {/* <Collapse in={error.length > 0}>
                <Alert severity="error"
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                        setError("");
                    }}
                    >
                    <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                sx={{ mb: 2, borderRadius: 3, fontSize: 12  }}
                >
                {error}
                </Alert>
            </Collapse> */}
        <div className='bg-white flex flex-col justify-between items-center py-4 w-full h-2/3 gap-3 rounded-2xl relative'>
          <LazyloadImg
            src={CLIENT_LOGO}
            alt={'Logo'}
            style={{ height: '80px', marginTop: '20px', marginBottom: '20px' }}
          ></LazyloadImg>
          <div className='flex flex-col justify-between h-3/4 w-4/5 font-medium'>
            <Link onClick={() => dispatch(remove())} to='/login'>
              <div className='flex flex-row justify-start items-center text-black gap-2 font-bold'>
                <ArrowBackIcon></ArrowBackIcon>
                <div className='text-black text-base'>Back to Log In</div>
              </div>
            </Link>

            <FormControl
              fullWidth
              sx={{}}
              variant='outlined'
              error={password1 !== '' && !isStrongPassword(password1)}
            >
              <InputLabel
                style={{ fontSize: 16, paddingRight: 6 }}
                htmlFor='outlined-adornment-password'
              >
                New Password
              </InputLabel>
              <OutlinedInput
                onChange={(e) => {
                  setPassword1(e.target.value);
                  !isStrongPassword(e.target.value)
                    ? setError(
                        'Your new password must be at least 8 characters long and contain at least 1 number, symbol, uppercase, and lowercase letter.',
                      )
                    : setError('');
                }}
                id='outlined-adornment-password'
                type={showPassword1 ? 'text' : 'password'}
                inputProps={{ style: { fontSize: 16 } }}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword1(!showPassword1)}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='New Password'
              />
            </FormControl>

            <FormControl
              fullWidth
              sx={{}}
              variant='outlined'
              error={
                password2 !== '' &&
                (password1 !== password2 ||
                  (password1 !== '' && !isStrongPassword(password1)))
              }
            >
              <InputLabel
                style={{ fontSize: 16, paddingRight: 6 }}
                htmlFor='outlined-adornment-password'
              >
                Confirm New Password
              </InputLabel>
              <OutlinedInput
                onChange={(e) => {
                  setPassword2(e.target.value);
                  e.target.value !== password1
                    ? setError('Passwords do not match.')
                    : !isStrongPassword(password1)
                    ? setError(
                        'Your new password must be at least 8 characters long and contain at least 1 number, symbol, uppercase, and lowercase letter.',
                      )
                    : setError('');
                }}
                id='outlined-adornment-password'
                type={showPassword2 ? 'text' : 'password'}
                inputProps={{ style: { fontSize: 16 } }}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword2(!showPassword2)}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Confirm New Password'
              />
            </FormControl>

            {error ? (
              <div className='text-red-600 text-xs font-medium h-10'>
                {error}
              </div>
            ) : (
              <div className='text-black text-xs font-medium h-10'>
                Your new password must be at least 8 characters long and contain
                at least 1 number, symbol, uppercase, and lowercase letter.
              </div>
            )}
            <Button
              disabled={password1 === '' || error !== '' || loading}
              sx={{
                backgroundColor: palette.primary.main,
              }}
              className='py-2 rounded-lg text-base font-semibold tracking-wider'
              variant='contained'
              disableElevation
              onClick={handleSubmit}
            >
              {loading ? (
                <div className='flex flex-row justify-center items-center gap-4'>
                  <CircularProgress
                    className='h-5 w-5'
                    color='inherit'
                  ></CircularProgress>
                  <div>{'Saving...'}</div>
                </div>
              ) : (
                <div>{'Save'}</div>
              )}
            </Button>
          </div>
        </div>
      </div>
      <BotBar></BotBar>
    </div>
  );
};

export default ChangePassword;
