import { useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../states/hooks';
import { storeOtp } from '../states/forgotPasswordSlice';
import BotBar from '../components/BotBar';
import LazyloadImg from 'components/LazyLoadingImg';
import { BACKEND_URL } from 'config';

import { Button, IconButton, Collapse, Alert, useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MuiOtpInput } from 'mui-one-time-password-input';
import CountDown from '../components/Countdown';
import { CLIENT_BACKGROUND, CLIENT_LOGO } from 'constants/constants';

const EnterOTP = () => {
	const { palette } = useTheme();
	const dispatch = useAppDispatch();
	const [otp, setOtp] = useState('');
	const [error, setError] = useState('');
	const [countdownCompleted, setCountdownCompleted] = useState(false);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const requestId = useAppSelector((state) => state.changePassword.id);

	const handleSubmit = async () => {
		setLoading(true);
		const response = await fetch(
			`${BACKEND_URL}/api/v1/anonymous/request/${requestId}/verify?otp=${otp}`,
			{
				method: 'GET',
				headers: { 'Content-Type': 'application/json' },
			},
		);

		const json = await response.json();

		try {
			if (response.ok) {
				setLoading(false);
				dispatch(storeOtp(otp));
				navigate('/change-password', { replace: true });
			} else {
				setLoading(false);
				setError(json.message);
			}
		} catch (e) {
			setLoading(false);
			setError('internal server error');
		}
	};

	const resendOTP = async () => {
		const response = await fetch(
			`${BACKEND_URL}/api/v1/anonymous/request/${requestId}/resend-otp`,
			{
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
			},
		);
		response.json().then(() => {
			setCountdownCompleted(false);
		});
	};

	return (
		<div
			className="login-container bg-local flex flex-col justify-center items-center h-full bg-cover bg-center"
			style={{ backgroundImage: `url(${CLIENT_BACKGROUND})` }}
		>
			<div className="flex flex-col justify-center h-full w-1/4 min-w-[400px]">
				<Collapse in={error.length > 0}>
					<Alert
						severity="error"
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setError('');
								}}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
						sx={{ mb: 2, borderRadius: 3, fontSize: 12 }}
					>
						{error}
					</Alert>
				</Collapse>
				<div className="bg-white flex flex-col justify-between items-center py-4 w-full h-2/3 gap-3 rounded-2xl relative">
					<LazyloadImg
						src={CLIENT_LOGO}
						alt={'Logo'}
						style={{ height: '80px', marginTop: '20px', marginBottom: '20px' }}
					></LazyloadImg>
					<div className="flex flex-col justify-between h-3/4 w-4/5 font-medium gap-2 text-base">
						<Link to="/forgot-password">
							<div className="flex flex-row justify-start items-center text-black gap-2 font-bold">
								<ArrowBackIcon></ArrowBackIcon>
								<div className="text-black text-base">Back to Log In</div>
							</div>
						</Link>
						<div className="text-black flex flex-col justify-center items-start gap-1">
							<div className="text-left text-lg font-bold">Forgot Password</div>
							<div className="text-left font-normal text-sm">
								Please enter the OTP sent to your email.
							</div>
						</div>
						<div className="flex h-10 flex-col ">
							<MuiOtpInput
								TextFieldsProps={{
									inputProps: {
										style: { fontSize: 14, paddingTop: 14, paddingBottom: 14 },
									},
								}}
								autoFocus={true}
								length={6}
								value={otp}
								validateChar={(char) => !isNaN(Number(char))}
								onChange={(e) => {
									setOtp(e);
								}}
							></MuiOtpInput>
						</div>
						<div>
							{!countdownCompleted ? (
								<div className="text-blue-900 font-semibold text-xs">
									Didnt receive the OTP?
								</div>
							) : (
								<div></div>
							)}
							<div
								className={
									countdownCompleted
										? 'text-black/25 text-xs'
										: 'text-black/75 text-xs'
								}
							>
								Resend the OTP in{' '}
								<CountDown
									seconds={!countdownCompleted ? 60 : 0}
									onFinish={() => setCountdownCompleted(true)}
								></CountDown>
							</div>
							{countdownCompleted ? (
								<a
									className="text-blue-900 hover:cursor-pointer text-xs"
									onClick={() => resendOTP()}
								>
									Resend OTP
								</a>
							) : (
								<div></div>
							)}
						</div>

						<Button
							disabled={!(otp.length === 6) || loading}
							sx={{ backgroundColor: palette.primary.main }}
							className="py-2 rounded-lg text-base font-semibold tracking-wider"
							variant="contained"
							disableElevation
							onClick={handleSubmit}
						>
							{loading ? (
								<div className="flex flex-row justify-center items-center gap-4">
									<CircularProgress
										className="h-5 w-5"
										color="inherit"
									></CircularProgress>
									<div>{'Submitting...'}</div>
								</div>
							) : (
								<div>{'Submit'}</div>
							)}
						</Button>
					</div>
				</div>
			</div>
			<BotBar></BotBar>
		</div>
	);
};

export default EnterOTP;
